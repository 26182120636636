// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-black-friday-js": () => import("./../../../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-dekovacka-testdrive-js": () => import("./../../../src/pages/dekovacka-testdrive.js" /* webpackChunkName: "component---src-pages-dekovacka-testdrive-js" */),
  "component---src-pages-demo-menu-js": () => import("./../../../src/pages/demo-menu.js" /* webpackChunkName: "component---src-pages-demo-menu-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jidalnicek-js": () => import("./../../../src/pages/jidalnicek.js" /* webpackChunkName: "component---src-pages-jidalnicek-js" */),
  "component---src-pages-novemenu-js": () => import("./../../../src/pages/novemenu.js" /* webpackChunkName: "component---src-pages-novemenu-js" */),
  "component---src-pages-novinky-js": () => import("./../../../src/pages/novinky.js" /* webpackChunkName: "component---src-pages-novinky-js" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-products-product-1-js": () => import("./../../../src/pages/products/product-1.js" /* webpackChunkName: "component---src-pages-products-product-1-js" */),
  "component---src-pages-products-product-2-js": () => import("./../../../src/pages/products/product-2.js" /* webpackChunkName: "component---src-pages-products-product-2-js" */),
  "component---src-pages-products-product-3-js": () => import("./../../../src/pages/products/product-3.js" /* webpackChunkName: "component---src-pages-products-product-3-js" */),
  "component---src-pages-products-product-4-js": () => import("./../../../src/pages/products/product-4.js" /* webpackChunkName: "component---src-pages-products-product-4-js" */),
  "component---src-pages-regulamin-programu-lojalnosciowego-js": () => import("./../../../src/pages/regulamin-programu-lojalnosciowego.js" /* webpackChunkName: "component---src-pages-regulamin-programu-lojalnosciowego-js" */),
  "component---src-pages-regulations-js": () => import("./../../../src/pages/regulations.js" /* webpackChunkName: "component---src-pages-regulations-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-specialni-nabidka-js": () => import("./../../../src/pages/specialni-nabidka.js" /* webpackChunkName: "component---src-pages-specialni-nabidka-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-affiliate-js": () => import("./../../../src/pages/thank-you-affiliate.js" /* webpackChunkName: "component---src-pages-thank-you-affiliate-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-order-js": () => import("./../../../src/pages/thank-you-order.js" /* webpackChunkName: "component---src-pages-thank-you-order-js" */),
  "component---src-pages-ukazka-jidelnicku-js": () => import("./../../../src/pages/ukazka-jidelnicku.js" /* webpackChunkName: "component---src-pages-ukazka-jidelnicku-js" */),
  "component---src-pages-ukazka-menu-index-js": () => import("./../../../src/pages/ukazka-menu/index.js" /* webpackChunkName: "component---src-pages-ukazka-menu-index-js" */),
  "component---src-pages-wyprobuj-menu-js": () => import("./../../../src/pages/wyprobuj-menu.js" /* webpackChunkName: "component---src-pages-wyprobuj-menu-js" */)
}

