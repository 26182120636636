
import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'

import { Loader } from '../components/loader'

import { RedirectModal } from '../components/redirect-modal'
import { RegulationsProgramComponent } from '../components/regulationsProgramComponent/regulationsProgramComponent'
import { SaleFormModal } from '../components/sale-form-modal'
import SEO from '../components/seo'
import { AffiliateContext } from '../contexts/AffiliateContext'


const PageLayout = ({ site }) => {
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate



  return (
    <>
      <SEO title="Home" />
      {!isAffiliate && <SaleFormModal formName="sale-form" />}
      <RedirectModal />
    
      <RegulationsProgramComponent />
    </>
  )
}

const RegulationsProgram = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  return (
    <>
      {site ? <PageLayout site={site} isAffiliate={isAffiliate} /> : <Loader />}
    </>
  )
}

export default RegulationsProgram
