import cx from 'classnames'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Trans, useTranslation } from 'react-i18next'

import { AnimatedWrapper } from '../animated-wrapper'
import { Button2 } from '../button2'
import { Container } from '../container'
import { ContactFormNew } from '../forms/contact-form-new'
import day1stack from './1den-boxes.png'
import day2stack from './2den-boxes.png'
import styles from './menuOrderInfo.module.css'

export const MenuOrderInfo = ({ id }) => {
  const { t } = useTranslation()
  const menuPage = window.location.href.includes('wyprobuj-menu')
  const demoPage = window.location.href.includes('demo-menu')
  const demoPageTitle = ' menu przez 2 dni po okazyjnej cenie'

  const menuPageTitle = ' menu przez 2 dni po okazyjnej cenie'

  return (
    <section className={styles.menuOrderInfo} id={id && id}>
      <Container isWide={true}>
        {/* <AnimatedWrapper> */}
        <h3
          className={cx(
            styles.sectionTitle,
            styles.sectionTitleDesk,
            'fancyUnderlineText text-center'
          )}
        >
          <span>Wypróbuj</span>
          {demoPage
            ? demoPageTitle
            : menuPage
            ? menuPageTitle
            : ' menu przez 2 dni z 15% rabatem'}
        </h3>
        {/* <h3
          className={cx(
            styles.sectionTitle,
            styles.sectionTitleMob,
            'fancyUnderlineText text-center'
          )}
        >
          <span>{t('home.menu.titleBlueghost.part1')}</span>{' '}
          {t('home.menu.titleBlueghost.part2')} <br />
          {t('home.menu.titleBlueghost.part3')}
        </h3> */}

        <div className={styles.promoTextWrapper}>
          {menuPage ? (
            <p className={styles.promoText}>
              Użyj tego kodu promocyjnego <b>TASTE50</b> w koszyku i zyskaj
              rabat <b>50 zł</b> na dwudniowe zamówienie{' '}
            </p>
          ) : demoPage ? (
            <p className={styles.promoText}>
              Użyj tego kodu promocyjnego <b>TASTE30</b> w koszyku i zyskaj rabat{' '}
              <b>30 zł</b> na dwudniowe zamówienie
            </p>
          ) : (
            <p className={styles.promoText}>
              Pozostaw nam swoje dane kontaktowe, a w ciągu dwóch godzin
              skontaktujemy się z Tobą.
            </p>
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.firstDay}>
            <div className={styles.label}>
              <span className={styles.labelNumber}>1.</span>
              <span>dzień</span>
            </div>
            <div className={styles.symbol}>{`{`}</div>
            <div>
              <img src={day1stack} className={styles.boxImg} alt="day1stack" />
            </div>
          </div>
          <div className={styles.plus}>+</div>
          <div className={styles.secondDay}>
            <div className={styles.label}>
              <span className={styles.labelNumber}>2.</span>
              <span>dzień</span>
            </div>
            <div className={styles.symbol}>{`}`}</div>
            <div>
              <img src={day2stack} className={styles.boxImg} alt="day2stack" />
            </div>
          </div>
        </div>
        {(menuPage ||
          demoPage) && (
            <div className={styles.buttonOnline}>
              <a
                style={{ color: '#fff', margin: '0 5px' }}
                href="https://order.nutritionpro.pl/zamowienie/"
                className={styles.btnLink}
                target="_blank"
              >
                <Button2 className={styles.button} color="secondary">
                  Zamów online
                </Button2>
              </a>
            </div>
          )}
        {!(menuPage ||
          demoPage) && (
            <div className={styles.formWrapper}>
              <div className={styles.form1col}>
                <div className={styles.oldPrice}>144 Zł</div>
                <div className={styles.newPrice}>
                  <span>Cena już od</span>
                  <span className={styles.price}>116 Zł</span>
                </div>
              </div>
              <div className={styles.form2col}>
                <ContactFormNew
                  themeLight={true}
                  horizontal={true}
                  form_name={'demo-objednavka'}
                  btnText="Proszę o kontakt"
                />
              </div>
            </div>
          )}
        {/* </AnimatedWrapper> */}
      </Container>
    </section>
  )
}
