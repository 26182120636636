import React from 'react'
import styles from './regulationsProgramComponent.module.css'
export const RegulationsProgramComponent = ({ id = '' }) => {


  return (
    <section id={id} className={styles.container}>
      <div className={styles.inner}>
       
      <h2 className={styles.contentItem}>§ 1 DEFINICJE REGULAMINU</h2>
        <p className={styles.contentItem}>
          <strong>NutritionPro Sp. z o.o.</strong> – spółka NutritionPro Sp. z o.o. z siedzibą przy Rondo Daszyńskiego 2B, Mazowieckie, 00-843, nr KRS 0001011605, NIP 5273035193, REGON 07752971, adres e-mail: info@nutritionpro.pl, numer telefonu: +48 799 352 730 – Organizator akcji „Program Lojalnościowy”, zwanej dalej również „Kateringiem”;
        </p>


        <p className={styles.contentItem}>
          <strong>Platforma Dietly.pl</strong> – określana dalej również jako „Platforma” (lub „Wyszukiwarka”) – to strona internetowa działająca pod adresem
          <a target="_blank" href="https://dietly.pl/">
            https://dietly.pl
          </a>
          umożliwiająca przeglądanie i porównywanie ofert różnych firm cateringowych, w tym Organizatora, a także składanie zamówień na wybrane zestawy dań dostępnych w ofercie Cateringu przedstawionym na 
          Platformie.
        </p>


        <p className={styles.contentItem}>
          <strong>Program lojalnościowy</strong>  – zwany dalej „Programem” – to specjalna inicjatywa skierowana do klientów NutritionPro, mająca na celu zachęcenie do składania zamówień poprzez Platformę Dietly, przy jednoczesnym zdobywaniu punktów lojalnościowych na warunkach określonych w niniejszym Regulaminie.
        </p>
        <p className={styles.contentItem}>
          <strong>Klient</strong>– osoba fizyczna lub prawna dokonująca zamówienia w NutritionPro za pośrednictwem Platformy Dietly.pl.
        </p>
        <p className={styles.contentItem}> 
          <strong>Zamówienie</strong>– deklaracja woli złożona przez Klienta przy użyciu funkcjonalności Platformy, obejmująca wybór jednego lub więcej produktów z oferty NutritionPro, opłaconych online za pośrednictwem Platformy, co prowadzi do zawarcia umowy sprzedaży i dostarczenia produktów na odległość między NutritionPro a Klientem.
        </p>
        <p className={styles.contentItem}>
          <strong>Konto Klienta</strong>– indywidualny panel administracyjny Klienta, dostępny po skutecznej rejestracji i zalogowaniu się na stronie internetowej{' '}
          <a href="https://dietly.pl/" target="_blank">
            www.dietly.pl
          </a>
          , za pomocą ustalonego wcześniej loginu i hasła. Konto umożliwia korzystanie z usług oferowanych przez NutritionPro, w tym składanie zamówień, zarządzanie nimi oraz edycję danych kontaktowych.

        </p>
        <p className={styles.contentItem}>
          <strong>Kod polecający</strong>– specjalny kod wygenerowany dla nowych klientów, którym uczestnik Programu Lojalnościowego może polecić ofertę NutritionPro. Kod dostępny jest w zakładce „Skarbonka”.
        </p>
        <p className={styles.contentItem}>
          <strong>Punkty lojalnościowe</strong>  – punkty przyznawane w ramach Programu, które Klient może wymieniać na dodatkowe usługi, np. zniżki na zakup diety.
        </p>
        <p className={styles.contentItem}>
          <strong>Skarbonka</strong>– sekcja w Koncie Klienta, w której gromadzone są przyznane punkty lojalnościowe.
        </p>


            
        <h2 className={styles.contentItem}>
        § 2 POSTANOWIENIA OGÓLNE
        </h2>
        <p className={styles.contentItem}>
            1.Organizatorem akcji promocyjnej pod nazwą „Program Lojalnościowy” jest NutritionPro Sp. z o.o.
        </p>
        <p className={styles.contentItem}>
      2. Program lojalnościowy ma zasięg ogólnokrajowy i jest przeznaczony dla osób fizycznych lub prawnych, które dokonują zamówień w NutritionPro za pośrednictwem Platformy Dietly.pl.
        </p>
        <p className={styles.contentItem}>
            3.Celem Programu jest promocja usług NutritionPro.
        </p>
        <p className={styles.contentItem}>
            4.NutritionPro udostępnia Klientom Regulamin w sposób nieodpłatny za pośrednictwem Platformy oraz umożliwia jego pobranie, zapisanie, wydrukowanie lub odtworzenie.
        </p>
        <p className={styles.contentItem}>
            5. Klient przed skorzystaniem z oferty NutritionPro w ramach Programu powinien zapoznać się z treścią Regulaminu. Przystąpienie do Programu oznacza akceptację zasad i warunków w nim określonych.
        </p>
        <p className={styles.contentItem}>
            6.Udział w Programie na warunkach przedstawionych w Regulaminie jest równoznaczny z zawarciem umowy o uczestnictwo w Programie.

        </p>
        <p className={styles.contentItem}>
            7. Klient w każdej chwili ma prawo do rezygnacji z uczestnictwa w Programie bez podania przyczyny i ze skutkiem natychmiastowym. Rezygnacja wymaga wysłania wiadomości e-mail na adres info@nutritionpro.pl wyłącznie z adresu powiązanego z Kontem Klienta. Wysłanie oświadczenia z innego adresu e-mail nie zostanie uznane przez Organizatora. W momencie otrzymania oświadczenia przez Organizatora, umowa o uczestnictwo zostaje rozwiązana, a zgromadzone punkty lojalnościowe przepadają.
        </p>
        <p className={styles.contentItem}>
            8. Organizator zastrzega sobie prawo do wypowiedzenia umowy z ważnych powodów z 14-dniowym okresem wypowiedzenia. Ważne powody obejmują m.in. naruszenie postanowień Regulaminu, próby obejścia zasad Programu lub naruszenie przepisów prawa. Po rozwiązaniu umowy wszystkie niewykorzystane punkty lojalnościowe zostaną anulowane.

        </p>
        <p className={styles.contentItem}>
          9.Organizator ma prawo do natychmiastowego rozwiązania umowy o uczestnictwo w Programie i usunięcia zgromadzonych punktów w przypadku uzasadnionego podejrzenia nadużyć, w szczególności związanych z transakcjami finansowymi.
        </p>

      
        <h2 className={styles.contentItem}>§ 3 TERMIN I MIEJSCE</h2>
        <p className={styles.contentItem}>
          1.NutritionPro zastrzega sobie prawo do:

        </p>
        <p className={styles.contentItem}>
        a) zakończenia Programu Lojalnościowego w dowolnym momencie,

        </p>
        <p className={styles.contentItem}>
        b) całkowitego usunięcia Programu ze swojej oferty w terminie ustalonym przez NutritionPro,

        </p>
        <p className={styles.contentItem}>
        c) usunięcia zgromadzonych punktów lojalnościowych w ramach Programu.

        </p>

        <p className={styles.contentItem}>
       2.  O planowanym zakończeniu Programu NutritionPro poinformuje Klientów za pośrednictwem strony internetowej 
          <a target="_blank" href="https://nutritionpro.pl/">
            https://nutritionpro.pl
          </a>{' '}
          oraz drogą e-mailową.
        </p>
        <p className={styles.contentItem}>
       3. W przypadku zakończenia Programu lub jego usunięcia z oferty NutritionPro, zgromadzone punkty lojalnościowe mogą zostać całkowicie usunięte po terminie określonym przez NutritionPro.

        </p>

        <p className={styles.contentItem}>
         4. Czas trwania Programu jest nieokreślony, jednak NutritionPro zastrzega sobie prawo do wprowadzania zmian zgodnie z postanowieniami Regulaminu.
        </p>

        <h2 className={styles.contentItem}>§ 4 ZASADY PROMOCJI</h2>
        <p className={styles.contentItem}>
       1. Aby zdobyć punkty lojalnościowe, konieczna jest rejestracja Konta Klienta na Platformie Dietly.pl, zakończona jego pozytywną weryfikacją pod kątem poprawności danych oraz zgodności z Regulaminem Platformy Dietly 
          <a
            target="_blank"
            href="https://s3.eu-central-1.amazonaws.com/terms-and-policies/Regulamin_platformy_Dietly.pdf"
          >
            (https://s3.eu-central-1.amazonaws.com/terms-and-policies/Regulamin_platformy_Dietly.pdf)
          </a>
          . Klient musi również wykonać wszystkie wymagane działania określone w niniejszym Regulaminie. W przypadku anulowania zamówienia, naruszenia zasad Programu, Regulaminu NutritionPro lub Regulaminu Platformy Dietly, braku dokonania płatności online albo błędu w rozliczeniu, punkty lojalnościowe mogą zostać anulowane lub w ogóle nie przyznane.

        </p>
        <p className={styles.contentItem}>
          2. Zweryfikowane Konto Klienta to takie, które pomyślnie przeszło proces rejestracji na Dietly.pl i zostało zatwierdzone przez Platformę. Konto to umożliwia składanie zamówień na produkty oferowane przez NutritionPro oraz zawieranie umów na ich sprzedaż i dostawę.

        </p>
        <p className={styles.contentItem}>
          3. Punkty lojalnościowe za założenie Konta Klienta z wyrażeniem zgód marketingowych są przyznawane po dokonaniu pierwszego zamówienia i jego opłaceniu. Testowe zamówienia (tzw. zamówienia próbne) nie uprawniają do zdobycia punktów.

        </p>
        <p className={styles.contentItem}>
          4.Klient ma możliwość wygenerowania unikalnego linku polecającego w swoim Panelu Klienta na Platformie Dietly.pl. Link ten może być udostępniony innej osobie, która w ramach Programu Lojalnościowego otrzyma kod rabatowy na pierwsze zamówienie. Po realizacji zamówienia przez poleconą osobę, Klientowi zostaną naliczone punkty lojalnościowe zgodnie z zasadami Programu.

        </p>
        <p className={styles.contentItem}>
          5.Kod polecający, o którym mowa powyżej, umożliwia nowemu Klientowi skorzystanie z rabatu przy pierwszym zamówieniu. Jeśli osoba polecona użyje tego kodu, Klient, który ją polecił, otrzyma dodatkowe punkty w sekcji „Skarbonka”.

        </p>
        <p className={styles.contentItem}>
          6.Punkty lojalnościowe są naliczane automatycznie na podstawie wartości zamówienia, przy czym standardowy przelicznik wynosi: 100 punktów = 1 PLN. Punkty mogą być naliczane wyłącznie za zamówienia opłacone online.

        </p>
        <p className={styles.contentItem}>
          7. Punkty lojalnościowe zebrane w NutritionPro można wykorzystać wyłącznie w tym 
        </p>
        <p className={styles.contentItem}>
          8.Punkty lojalnościowe za różne działania w Programie są przyznawane według następującego schematu:

        </p>
        <ul>
          <li>
            {' '}
            <p className={styles.contentItem}>
            Za rejestrację konta i pierwsze zamówienie z wyrażeniem zgód marketingowych: 1000 punktów (równowartość 10 PLN).

            </p>
          </li>
          <li>
            {' '}
            <p className={styles.contentItem}>
            Za rejestrację konta i pierwsze zamówienie bez zgód marketingowych: 500 punktów (równowartość 5 PLN).
            </p>
          </li>
          <li>
            {' '}
            <p className={styles.contentItem}>
            Za każde wydane pełne 50 PLN podczas pierwszego zamówienia poleconej osoby: 100 punktów (równowartość 1 PLN).

            </p>
          </li>
          <li>
            {' '}
            <p className={styles.contentItem}>
            Za każde wydane pełne 50 PLN podczas zamówienia w Panelu Klienta: 50 punktów (równowartość 50 groszy PLN).

            </p>
          </li>
        </ul>

        <p className={styles.contentItem}>
       9. Punkty lojalnościowe naliczane są tylko dla zamówień powyżej 50 PLN brutto, przy czym naliczanie odbywa się za każde kolejne pełne 50 PLN.
        </p>
        <p className={styles.contentItem}>
          10. Klient może wykorzystać 100 zgromadzonych punktów do obniżenia wartości zamówienia o 1 PLN brutto, pod warunkiem, że zapłaci przynajmniej 1 PLN brutto z własnych środków.
        </p>
        <p className={styles.contentItem}>
          11. Za ocenę posiłku w ramach aktywnego zamówienia na Platformie Dietly.pl przyznawane jest 10 punktów lojalnościowych (równowartość 10 groszy PLN).

        </p>
        <p className={styles.contentItem}>
          12. Za napisanie recenzji o NutritionPro zawierającej co najmniej 100 znaków (bez spacji) na Platformie Dietly.pl przyznawane jest 100 punktów lojalnościowych (równowartość 1 PLN).

        </p>
        <p className={styles.contentItem}>
          13. NutritionPro informuje, że zgodnie z art. 21 ust. 1 pkt 68 ustawy z dnia 26 lipca 1991 r. o podatku dochodowym od osób fizycznych, nagrody związane ze sprzedażą premiową są zwolnione z podatku dochodowego, o ile ich jednorazowa wartość nie przekracza 2000 PLN. Zwolnienie to nie dotyczy nagród związanych z działalnością gospodarczą.

        </p>
        <p className={styles.contentItem}>
          14. Klient może zdobyć punkty za wyrażenie zgód marketingowych tylko raz – przy pierwszym zamówieniu na Platformie Dietly.pl w dowolnym cateringu oferującym program lojalnościowy. Wyrażenie tych zgód w innym cateringu wyklucza uzyskanie dodatkowych punktów w NutritionPro.
        </p>
        <p className={styles.contentItem}>
          15. Punkty lojalnościowe nie mogą być wymieniane na gotówkę ani przekazywane osobom trzecim.

        </p>
        <p className={styles.contentItem}>
          <strong>
            16. Punkty lojalnościowe uznaje się za przyznane z chwilą ich zarejestrowania w sekcji „Skarbonka” w Panelu Klienta na Platformie Dietly.pl.
          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            17. Punkty lojalnościowe nie są naliczane za zamówienia opłacone w całości zgromadzonymi punktami. Jeśli zamówienie jest częściowo opłacone punktami, punkty są naliczane wyłącznie od kwoty zapłaconej środkami pieniężnymi.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            18.Punkty lojalnościowe są przechowywane w sekcji „Skarbonka” w Panelu Klienta i aktualizowane po każdym zrealizowanym działaniu kwalifikującym się do ich zdobycia.
          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            19.Jeśli Klient posiada zarówno punkty zgromadzone w ramach programu „Punkty Dietly” na Platformie Dietly.pl, jak i punkty NutritionPro w „Skarbonce”, to przed ich wykorzystaniem w NutritionPro w pierwszej kolejności rozliczane są punkty zgromadzone w „Skarbonce”.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            20. Punkty zgromadzone w ramach zamówień NutritionPro są niezależne od punktów przyznawanych przez inne cateringi na Platformie Dietly.pl. Zgromadzone punkty mogą być wykorzystane wyłącznie w NutritionPro i nie podlegają przeniesieniu między cateringami.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            21. W przypadku likwidacji Panelu Klienta lub rezygnacji z Programu Lojalnościowego wszystkie zgromadzone punkty lojalnościowe zostają bezpowrotnie anulowane.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            22. Jeśli z jakichkolwiek powodów punkty lojalnościowe nie zostaną prawidłowo naliczone lub w ogóle nie zostaną przyznane, Klient powinien skontaktować się z NutritionPro drogą mailową, wysyłając zgłoszenie na adres info@nutritionpro.pl z dopiskiem „Reklamacja Programu Lojalnościowego Skarbonka”.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            23. Punkty zgromadzone przez Klienta w ramach Programu Lojalnościowego nie podlegają wymianie na środki pieniężne (PLN) oraz nie mogą być przekazywane innym osobom lub podmiotom.

          </strong>
        </p>

        <h2 className={styles.contentItem}>§ 5 POSTĘPOWANIE REKLAMACYJNE        </h2>
        <p className={styles.contentItem}>
          <strong>
            1. Reklamacje będą rozpatrywane na podstawie niniejszego Regulaminu.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            2. Reklamacje związane z Programem Lojalnościowym można zgłaszać wyłącznie w formie mailowej na adres: info@nutritionpro.pl z dopiskiem „Reklamacja Programu Lojalnościowego Skarbonka”.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            3.Dane osobowe przekazane w związku z reklamacją będą przetwarzane wyłącznie w celach związanych z reklamacją i/lub Programem Lojalnościowym. Administratorem danych osobowych jest NutritionPro Sp. z o.o. Podanie danych jest dobrowolne. Osobie, która udostępniła swoje dane, przysługuje prawo dostępu do nich, ich poprawiania oraz żądania zaprzestania przetwarzania.
          </strong>
        </p>
        <p  className={styles.contentItem}>
          {' '}
         
          <strong>
            4. Reklamacje będą rozpatrywane przez Organizatora w terminie 14 dni od dnia ich otrzymania. Decyzja Organizatora w sprawie reklamacji jest ostateczna i wiążąca, co nie ogranicza prawa Klienta do dochodzenia swoich roszczeń na podstawie obowiązujących przepisów prawa.

          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>
            5. W zakresie nieobjętym niniejszym Regulaminem zastosowanie znajdują postanowienia Regulaminu NutritionPro, Regulaminu świadczenia usług na Dietly.pl oraz Polityki Prywatności, dostępnych pod adresami:
          </strong>
        </p>
        <ul>
          <li>
            <p className={styles.contentItem}>
              <strong>
              Polityka Prywatności: https://nutritionpro.pl/personal-data

              </strong>
            </p>
          </li>
          <li>
            <p className={styles.contentItem}>
              <strong>
              Regulamin świadczenia usług NutritionPro: https://nutritionpro.pl/regulations

              </strong>
            </p>
          </li>
        </ul>
      </div>
    </section>
  )
}
