import { window } from "browser-monads"
import cx from "classnames"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AffiliateContext } from "../../../contexts/AffiliateContext"
import { handleMenuLinkClick } from "../../../helpers"
import { useLangContext } from "../../../utils/lang"
import { Button } from "../../button"
import { LocalizedLink } from "../../localized-link"
import { useModal } from "../../modal"
import styles from "./navbar.module.css"
import { Button2 } from "../../button2";
import IconClose from '../icons/burger-menu-icon.svg'
import IconMenu from '../icons/icon-menu.svg'
export const Navbar = ({ menuVisible, menuLinks, location, ...props }) => {
  const { lang } = useLangContext()

  const [menu, setMenu] = useState(menuLinks)

  const openMobileMenu = () => () => {
    props.onCloseMobileMenu()
  }

  const { show, hide, RenderModal } = useModal()

  const { t } = useTranslation()

  const getLinkTranslation = name => {
    return t(`${name}`)
  }

  const isHomepage = window.location.pathname === "/" || window.location.pathname === "/en/"

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate

  useEffect(() => {
    setMenu(menuLinks)
  }, [menuLinks])

  const HomepageMenu = () => {
    return (
      menuLinks &&
      menuLinks.map((link, i) => {
        return link.link.startsWith("/#") ? (
          <Button
            key={link.name}
            type="unstyled"
            className={cx(styles.navbarItem, "visible-desktop")}
            handleClick={() => handleMenuLinkClick(link, lang)}
          >
            {getLinkTranslation(link.name)}
          </Button>
        ) : (
          <div key={link.name}>
            {isAffiliate && link.name !== "Pro firmy" && (
              <LocalizedLink
                key={link.name}
                to={link.link}
                className={cx(styles.navbarItem, "visible-desktop")}
              >
                {getLinkTranslation(link.name)}
                {link.name === "Kariéra"}
              </LocalizedLink>
            )}
            {!isAffiliate && (
              <LocalizedLink
                key={link.name}
                to={link.link}
                className={cx(styles.navbarItem, "visible-desktop")}
              >
                {getLinkTranslation(link.name)}
                {link.name === "Kariéra"}
              </LocalizedLink>
            )}
          </div>
        )
      })
    )
  }

  const InnerPageMenu = () => {
    return menu ? (
      menu.map((link, i) => {
        return (
          !link.link.startsWith("/#") && (
            <LocalizedLink
              key={link.name}
              to={link.link}
              className={cx(styles.navbarItem, "visible-desktop")} 
            >
              {getLinkTranslation(link.name)}
            </LocalizedLink>
          )
        )
      })
    ) : (
      <div />
    )
  }

  return (
    <div className={styles.navbar}>
      {/* <a href="tel:+1234567890" className={styles.navbarItem}>
        <svg
          fill="none"
          height="35"
          viewBox="0 0 34 35"
          width="34"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m7.08333 6.27979h5.66667l2.8333 7.08331-3.5416 2.125c1.5172 3.0764 4.007 5.5662 7.0833 7.0834l2.125-3.5417 7.0833 2.8333v5.6667c0 .7514-.2985 1.4721-.8298 2.0035-.5314.5313-1.2521.8298-2.0035.8298-5.526-.3358-10.7381-2.6824-14.6528-6.5972-3.91474-3.9147-6.26138-9.1267-6.5972-14.65278 0-.75145.29851-1.47212.82986-2.00347.53136-.53135 1.25203-.82986 2.00347-.82986"
            stroke="#3dc383"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.125"
          />
        </svg>
      </a> */}
      <div className={styles.navbarCol}>
        {/* <GiftVoucherModal
          btnClassName={cx(styles.navbarItem, "visible-desktop")}
        /> */}
        {isHomepage ? <HomepageMenu /> : <InnerPageMenu />}
        {/* <a
          href="/blog/posts"
          className={cx(styles.navbarItem, "visible-desktop")}
        >
          Blog
        </a> */}
      </div>
      <div className={styles.navbarCol}>
        <span className={cx(styles.additionalInfo, "visible-desktop")}>
          <p style={{ textAlign: "right" }}>
            <span className={styles.days}>Pn-Pt</span> 8:00 - 16:00
          </p>
        </span>
        <a
          href="tel:+48226007328"
          className={cx(styles.navbarItem, styles.contacts, "visible-desktop")}
        >
          <span className="visible-desktop">
            <span className={styles.phoneNumber}>+48 22 600 73 28</span>
          </span>
          {/* <svg
            className={styles.iconPhone}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13.5" cy="13.5" r="13.5" fill="#3DC383" />
          </svg> */}
          <svg
            className={styles.iconPhone}
            width="34"
            height="35"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.08333 6.27979H12.75L15.5833 13.3631L12.0417 15.4881C13.5589 18.5645 16.0487 21.0543 19.125 22.5715L21.25 19.0298L28.3333 21.8631V27.5298C28.3333 28.2812 28.0348 29.0019 27.5035 29.5333C26.9721 30.0646 26.2514 30.3631 25.5 30.3631C19.974 30.0273 14.7619 27.6807 10.8472 23.7659C6.93246 19.8512 4.58582 14.6392 4.25 9.11312C4.25 8.36167 4.54851 7.641 5.07986 7.10965C5.61122 6.5783 6.33189 6.27979 7.08333 6.27979"
              stroke="#3DC383"
              strokeWidth="2.125"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
        <a
          style={{ color: '#fff', margin: '0 5px' }}
          href='https://order.nutritionpro.pl/zamowienie/'
          className={styles.btnLink}
          target='_blank'
        >
          <Button2 className={styles.button} color="secondary">
            Zamów online
          </Button2>
        </a>
        {/* <a
          style={{ display: 'flex', marginLeft: '24px' }}
          href="https://nutritionpro.pl"
          target="_blank"
        >
          <svg
            version="1.1"
            width="27"
            height="27"
            viewBox="0 0 256 256"
            xmlSpace="preserve"
          >
            <g
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                fill: 'none',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              transform="matrix(2.81 0 0 2.81 1.4065934065934016 1.4065934065934016)"
            >
              <path
                d="M 45 90 C 20.147 90 0 69.853 0 45 h 90 C 90 69.853 69.853 90 45 90 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(220,20,60)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform="matrix(1 0 0 1 0 0)"
                strokeLinecap="round"
              />
              <path
                d="M 45 0 C 20.147 0 0 20.147 0 45 h 90 C 90 20.147 69.853 0 45 0 z"
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(243,244,245)',
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                transform="matrix(1 0 0 1 0 0)"
                strokeLinecap="round"
              />
            </g>
          </svg>
        </a> */}
        {/*<a*/}
        {/*  href="https://nutritionpro.cz/en/"*/}
        {/*  target="_blank"*/}
        {/*  className={cx(styles.navbarItem, styles.langSwitcher)}>*/}
        {/*  <svg*/}
        {/*    style={{opacity: 0.5}}*/}
        {/*    width="28"*/}
        {/*    height="27"*/}
        {/*    viewBox="0 0 28 27"*/}
        {/*    fill="none"*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*  >*/}
        {/*    <path*/}
        {/*      d="M14.4618 27C21.9106 27 27.9491 20.9558 27.9491 13.5C27.9491 6.04416 21.9106 0 14.4618 0C7.01304 0 0.974609 6.04416 0.974609 13.5C0.974609 20.9558 7.01304 27 14.4618 27Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M3.76338 5.28027C2.70395 6.65996 1.90509 8.25017 1.43994 9.97769H8.45635L3.76338 5.28027Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M27.4846 9.97862C27.0194 8.25115 26.2205 6.66094 25.1611 5.28125L20.4683 9.97862H27.4846Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M1.43994 17.0215C1.90515 18.749 2.704 20.3392 3.76338 21.7188L8.45619 17.0215H1.43994Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M22.6739 2.79054C21.2955 1.7301 19.7068 0.930488 17.981 0.464844V7.48785L22.6739 2.79054Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M6.25073 24.209C7.62912 25.2694 9.21782 26.069 10.9437 26.5347V19.5117L6.25073 24.209Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M10.9436 0.464844C9.21776 0.930488 7.62906 1.7301 6.25073 2.79048L10.9436 7.4878V0.464844Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M17.981 26.5347C19.7068 26.069 21.2955 25.2694 22.6738 24.209L17.981 19.5117V26.5347Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M20.4683 17.0215L25.1611 21.7189C26.2205 20.3392 27.0194 18.749 27.4846 17.0215H20.4683Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M27.8349 11.7391H16.2211L16.2211 0.114275C15.6452 0.0392344 15.0581 0 14.4618 0C13.8655 0 13.2785 0.0392344 12.7026 0.114275V11.739L1.08878 11.7391C1.01381 12.3155 0.974609 12.9032 0.974609 13.5C0.974609 14.0969 1.01381 14.6845 1.08878 15.2609H12.7025L12.7026 26.8857C13.2785 26.9608 13.8655 27 14.4618 27C15.0581 27 15.6452 26.9608 16.221 26.8857V15.261L27.8349 15.2609C27.9099 14.6845 27.9491 14.0969 27.9491 13.5C27.9491 12.9032 27.9099 12.3155 27.8349 11.7391Z"*/}
        {/*      fill="#D80027"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M17.98 17.0215L23.9984 23.0457C24.2752 22.7687 24.5393 22.4792 24.7912 22.179L19.6386 17.0215H17.98V17.0215Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M10.9436 17.0215H10.9435L4.92505 23.0456C5.20175 23.3227 5.49104 23.587 5.79092 23.8391L10.9436 18.6815V17.0215Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M10.9431 9.97843V9.97832L4.92463 3.9541C4.64783 4.23106 4.38377 4.52063 4.13184 4.82079L9.28453 9.97838H10.9431V9.97843Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M17.98 9.97792L23.9985 3.95365C23.7218 3.67658 23.4325 3.41228 23.1326 3.16016L17.98 8.31774V9.97792V9.97792Z"*/}
        {/*      fill="#0052B4"*/}
        {/*    />*/}
        {/*  </svg>*/}
        {/*</a>*/}
        <div
          className={cx(styles.navbarItem, styles.mobileMenuBtn)}
          onClick={openMobileMenu()}
          id="mobile-menu-link"
        >
          <img src={menuVisible ? IconClose : IconMenu} alt="icon" />
        </div>
      </div>
    </div>
  )
}
