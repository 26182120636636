import React from 'react'
import styles from './regulationsPart.module.css'
export const RegulationsPart = ({ id = '' }) => {
  const handleClickEmail = () => {
    const emailAddress = 'info@nutritionpro.pl'
    window.open(`mailto:${emailAddress}`, '_blank')
  }

  return (
    <section id={id} className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.title}>
          {' '}
          <h1 className={styles.heading}>Regulamin </h1>
          <p>
            Nutritionpro Sp. z o.o. z siedzibą: Rondo Daszyńskiego 2B,
            Mazowieckie, 00-843
          </p>
        </div>
        <h2 className={styles.contentItem}>I. Postanowienie wstępne</h2>
        <p className={styles.contentItem}>
          <strong>
            Dla celów niniejszego Regulaminu poniższe terminy mają następujące
            znaczenie:
          </strong>
        </p>
        <p className={styles.contentItem}>
          Nutritionpro Sp. z o.o. z siedzibą: Rondo Daszyńskiego 2B,
          Mazowieckie, 00-843 no. 07752971, NIP 5273035193, KRS 0001011605,
          email: info@nutritionpro.pl, phone: +48 22 600 73 28 (dalej jako
          "Dostawca")
        </p>
        <p className={styles.contentItem}>
          Plan NutritionPro to plan zdrowego odżywiania polegający na
          przygotowaniu i dostarczeniu przez Dostawcę codziennych zestawów
          posiłków według wybranej i zamówionej przez Klienta liczby cykli
          dziennych/tygodniowych/miesięcznych do miejsca wskazanego przez
          Klienta lub do punktu odbioru (dalej jako NutritionPro Plan.)
        </p>
        <p className={styles.contentItem}>
          Dzienny zestaw posiłków to zbilansowana kompozycja posiłków na jeden
          dzień, dostarczana Klientowi przez Dostawcę usług. Dzienny zestaw
          posiłków będzie dostosowany do poszczególnych Klientów, aby zapewnić
          optymalne spożycie makroskładników na podstawie informacji podanych we
          wstępnym kwestionariuszu wypełnionym przez Klienta oraz danych
          wejściowych dotyczących składu ciała. Posiłki w każdym zestawie
          dziennym są wyraźnie zamówione, oznaczone, pakowane i dostarczane
          Klientowi codziennie do miejsca wskazanego przez Klienta lub do punktu
          odbioru.
        </p>
        <p className={styles.contentItem}>
          Klient to osoba zamawiająca Plan NutritionPro na stronie
          www.nutritionpro.pl jako osoba zainteresowana oferowanymi usługami,
          które polegają na regularnym przyjmowaniu odżywczo zbilansowanej i
          zdrowej żywności. Klient zobowiązuje się do uiszczenia uzgodnionej
          opłaty za te usługi na rzecz Dostawcy usług (dalej jako "Klient").
        </p>
        <h2 className={styles.contentItem}>II. Zamówienie</h2>
        <p className={styles.contentItem}>
          <strong>
            Dla celów niniejszego Regulaminu poniższe terminy mają następujące
            znaczenie:
          </strong>
        </p>
        <p className={styles.contentItem}>
          Nutritionpro Sp. z o.o. z siedzibą: Rondo Daszyńskiego 2B,
          Mazowieckie, 00-843 no. 07752971, NIP 5273035193, KRS 0001011605,
          email: info@nutritionpro.pl, phone: +48 22 600 73 28 (dalej jako
          "Dostawca")
        </p>
        <p className={styles.contentItem}>
          Plan NutritionPro to plan zdrowego odżywiania polegający na
          przygotowaniu i dostarczeniu przez Dostawcę codziennych zestawów
          posiłków według wybranej i zamówionej przez Klienta liczby cykli
          dziennych/tygodniowych/miesięcznych do miejsca wskazanego przez
          Klienta lub do punktu odbioru (dalej jako NutritionPro Plan.)
        </p>
        <p className={styles.contentItem}>
          Dzienny zestaw posiłków to zbilansowana kompozycja posiłków na jeden
          dzień, dostarczana Klientowi przez Dostawcę usług. Dzienny zestaw
          posiłków będzie dostosowany do poszczególnych Klientów, aby zapewnić
          optymalne spożycie makroskładników na podstawie informacji podanych we
          wstępnym kwestionariuszu wypełnionym przez Klienta oraz danych
          wejściowych dotyczących składu ciała. Posiłki w każdym zestawie
          dziennym są wyraźnie zamówione, oznaczone, pakowane i dostarczane
          Klientowi codziennie do miejsca wskazanego przez Klienta lub do punktu
          odbioru.
        </p>
        <p className={styles.contentItem}>
          Klient to osoba zamawiająca Plan NutritionPro na stronie
          www.nutritionpro.pl jako osoba zainteresowana oferowanymi usługami,
          które polegają na regularnym przyjmowaniu odżywczo zbilansowanej i
          zdrowej żywności. Klient zobowiązuje się do uiszczenia uzgodnionej
          opłaty za te usługi na rzecz Dostawcy usług (dalej jako "Klient").
        </p>
        <h2 className={styles.contentItem}>
          III. Zmiana/anulowanie zamówienia
        </h2>
        <p className={styles.contentItem}>
          1. Klient zobowiązany jest do poinformowania Usługodawcy o wszelkich
          zmianach w zamówieniu, takich jak w szczególności rezygnacja z Planu
          NutritionPro lub zmiana zamówionego planu w terminie co najmniej 3 dni
          roboczych przed dniem, w którym Plan NutritionPro ma zostać
          dostarczony. Zmiany zgłoszone przez Klienta w terminie krótszym niż
          określony w zdaniu poprzednim nie będą akceptowane, Plan Nutrition
          Prozostanie należycie dostarczony Klientowi, a Klient zobowiązany jest
          do uiszczenia opłaty za dostarczony Plan NutritionPro na rzecz
          Dostawcy w pełnej wysokości.
        </p>
        <p className={styles.contentItem}>
          2. Jeśli Klient zmieni/anuluje zamówienie w godzinach pracy obsługi
          klienta, tj. pn-pt od 8.00 do 16.00 pod numerem telefonu +48 799 352
          730, ale nie później niż 3 dni robocze przed dostawą (z wyłączeniem
          dnia dostawy, tj. dzień dostawy minus 3 całe dni robocze), Usługodawca
          zmieni/anuluje to zmienione/anulowane zamówienie.
        </p>
        <h2 className={styles.contentItem}>IV. Dostawa</h2>
        <p className={styles.contentItem}>
          1. Usługodawca zobowiązuje się do przygotowania Planu NutritionPro
          zgodnie z zamówieniem Klienta, dostarczenia Planu NutritionPro do
          miejsca wskazanego przez Klienta na własny koszt oraz przekazania go
          Klientowi w sposób określony w ust. 2 niniejszego paragrafu.
        </p>
        <p className={styles.contentItem}>
          2. Dostawa realizowana jest wyłącznie w umówionym czasie i
          lokalizacji. W przypadku nieodebrania dostawy przez Klienta w
          umówionym czasie i miejscu, Usługodawca nie dokonuje ponownej dostawy.
        </p>{' '}
        <p className={styles.contentItem}>
          3. Dostawa realizowana jest standardowo przez pracujące na zlecenie
          Usługodawcy firmy kurierskie, logistyczne.
        </p>{' '}
        <p className={styles.contentItem}>
          4. Dzienny zestaw posiłków musi zostać spożyty nie później niż do dnia
          określonego na etykiecie. Po otwarciu należy je niezwłocznie spożyć.
        </p>{' '}
        <p className={styles.contentItem}>
          5. Dzienny zestaw posiłków zostanie dostarczony do wskazanego miejsca
          w godzinach od 17 do 9. Klient jest świadomy, że okres ten może ulec
          zmianie w wyjątkowych przypadkach związanych z ruchem drogowym i
          innymi podobnymi wydarzeniami. O każdej takiej zmianie Klient zostanie
          poinformowany telefonicznie.
        </p>{' '}
        <p className={styles.contentItem}>
          6. W przypadku konieczności zmiany uzgodnionej godziny dostawy do
          wskazanego przez Klienta miejsca, Klient jest zobowiązany do dokonania
          tej zmiany e-mailem lub telefonicznie pod numerem +48 22 600 73 28 do
          godziny 15.00 w dniu dostawy dziennego zestawu posiłków.
        </p>
        <h2 className={styles.contentItem}>
          V. Brak zgodności z umową, postępowanie reklamacyjne
        </h2>
        <p className={styles.contentItem}>
          1. Dostawca nie ponosi odpowiedzialności za wady spowodowane przez:
          nieuprawnioną interwencję, niewłaściwe przechowywanie,
          nieprofesjonalne obchodzenie się z towarem, użycie towaru do innych
          niż określone celów, siłę wyższą po przekazaniu towaru Klientowi.
        </p>
        <p className={styles.contentItem}>
          2. Przy odbiorze towaru Kupujący zobowiązany jest do sprawdzenia
          towaru pod kątem ewentualnych wad powstałych podczas transportu. W
          przypadku stwierdzenia wad, Kupujący zobowiązany jest nie przyjmować
          towaru i niezwłocznie poinformować o tym Dostawcę pod adresem
          info@nutritionpro.pl. Następnie Kupujący zobowiązany jest do
          bezzwłocznego sprawdzenia dostarczonego towaru, w szczególności ilości
          i rodzaju dostarczonego towaru. Wszelkie stwierdzone wady należy
          niezwłocznie zgłosić. Późniejsze reklamacje nie będą brane pod uwagę.
        </p>
        <p className={styles.contentItem}>
          3. W przypadku reklamacji jakości posiłków, reklamowany towar zostanie
          odebrany przez kierowcę Dostawcy. Przy reklamacji ilości posiłków nie
          może brakować więcej niż 10% porcji. W przypadku uznania przez
          Dostawcę reklamacji, zostanie ona rozwiązana poprzez wymianę na inny
          posiłek na tym samym poziomie cenowym lub poprzez zwrot ceny zakupu w
          kolejnym zamówieniu.
        </p>
        <p className={styles.contentItem}>
          4. Klient zobowiązany jest udowodnić zasadność swojej reklamacji,
          poprzez dołączenie zdjęcia dokumentującego reklamowaną wadę.
        </p>
        <p className={styles.contentItem}>
          5. Sprzedawca ustosunkuje się do żądania Klienta w ciągu 2 dni
          roboczych, nie później niż w terminie 14 dni od momentu zgłoszenia
          reklamacji. Zawiadomienia o wadach dotyczących Produktu oraz
          zgłoszenie odpowiedniego żądania można dokonać za pośrednictwem poczty
          elektronicznej na adres:
          <a href='' onClick={handleClickEmail}> info@nutritionpro.pl </a>
        </p>
        <h2 className={styles.contentItem}>VI. Cena planu NutritionPro</h2>
        <p className={styles.contentItem}>
          1. Ceny podane w Serwisie są łączna ceną Planu oraz Dostawy. Ceny są
          cenami brutto, uwzględniającymi należne podatki, a w szczególności
          podatek od towarów i usług VAT.
        </p>
        <p className={styles.contentItem}>
          2. Klient zobowiązuje się odebrać zamówiony Plan NutritionPro od
          Dostawcy we wskazanym miejscu i należycie i terminowo zapłacić
          Dostawcy cenę zakupu za swój Plan.
        </p>
        <p className={styles.contentItem}>
          3. Usługodawca wystawi Klientowi fakturę z terminem płatności w ciągu
          7 dni od złożenia zamówienia. Dostawca ma prawo wystawić fakturę
          Klientowi w dniu, w którym Klient zamówi Plan NutritionPro i wysłać
          fakturę Klientowi pocztą elektroniczną.
        </p>
        <p className={styles.contentItem}>
          4. Na podstawie wystawionej faktury Klient zapłaci cenę zakupu
          Usługodawcy bezgotówkowym przelewem na konto bankowe Usługodawcy lub
          podczas składania zamówienia na stronie NutritionPro.pl za
          pośrednictwem PayU.
        </p>
        <p className={styles.contentItem}>
          5. Cenę kupna uważa się za zapłaconą w momencie zaksięgowania jej na
          koncie Dostawcy.
        </p>
        <p className={styles.contentItem}>
          6. W przypadku zwłoki w zapłacie ceny zakupu Dostawca może odmówić
          dostarczenia zamówionego Planu do czasu całkowitego zapłacenia ceny
          zakupu za wcześniej dostarczony Plan NutritionPro.
        </p>
        <h2 className={styles.contentItem}>VII. Postanowienia końcowe</h2>
        <p className={styles.contentItem}>
          1. Wszelkie warunki, które nie zostały wyraźnie uregulowane w
          niniejszej umowie, podlegają ogólnie obowiązującym przepisom prawnym
          Rzeczypospolitej Polski.
        </p>
        <p className={styles.contentItem}>
          2. Niniejsze wiążące Warunki są ważne i obowiązują od dnia ich
          opublikowania na stronach internetowych Dostawcy. O wszelkich zmianach
          lub uzupełnieniach niniejszego Regulaminu Usługodawca poinformuje
          Klienta z wyprzedzeniem. Jeżeli którekolwiek z postanowień niniejszego
          Regulaminu jest nieważne lub nieskuteczne, nie narusza to ważności
          Regulaminu jako całości. Dostawca zobowiązuje się zastąpić takie
          nieważne lub nieskuteczne postanowienie innym postanowieniem i
          poinformować o tym Klienta.Jeśli Klient nie wyrazi sprzeciwu wobec
          zmiany w ciągu 30 dni od dnia jej ogłoszenia, uważa się, że Klient
          zgodził się na zmianę Warunków.
        </p>
        <p className={styles.contentItem}>
          3. Akceptację Regulaminu przez Klienta uważa się za wiążącą dla
          Klienta i Dostawcy Usług, a Klient nie ma żadnych zastrzeżeń co do
          treści Umowy i Regulaminu.
        </p>
      </div>
    </section>
  )
}
