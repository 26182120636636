import cx from 'classnames'
import React, {useContext, useEffect, useReducer, useState} from 'react'
import {Fade} from 'react-awesome-reveal'
import {Trans, useTranslation} from 'react-i18next'

import {AffiliateContext} from '../../contexts/AffiliateContext'
import {useHomepageTabsContext} from '../../contexts/HomepageTabsContext'
import {AnimatedWrapper} from '../animated-wrapper'
import {Button} from '../button'
import {Button2} from '../button2'
import styles from '../calculator/calculator2.module.css'
import {Container} from '../container'
import {MainForm2} from '../forms/main-form2'
import {OrderForm2} from '../forms/order-form2'
import styles2 from './order-form2.module.css'

export const Order2 = ({id, className}) => {
  const isOnlineFormLinkAvailable =
    window.location.href.indexOf('krabickova-dieta-') > -1

  const {t} = useTranslation()

  const affiliateContext = useContext(AffiliateContext)

  const testovani = localStorage.getItem("website-version")

  return (
    <section
      // style={{
      //   backgroundColor: 'var(--color-grey-light)',
      //   padding: '24px 0',
      //   position: 'relative',
      //   overflow: 'hidden',
      //   width: '100%'
      // }}
      className={cx(styles2.section, className)}
      id={id}
    >
      <Container className={cx('text-center', styles2.container)}>
        {/* <AnimatedWrapper> */}
        <>
          <h3
            className={cx(
              'fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center',
              styles.title,
              styles2.title
            )}
            style={{marginBottom: `8px !important`}}
          >
            Dobierz swoją dietę
          </h3>
          {!isOnlineFormLinkAvailable && (
            <p style={{marginBottom: '32px'}}>
              Przelicz swoją cenę bez zobowiązań lub{' '}
              <a
                href='https://order.nutritionpro.pl/zamowienie/'
                 style={{fontWeight: '700', color: '#44C789'}}
              >
                zamów online
              </a>
              .
            </p>
          )}
        </>
        {/* <p className={"text-center"}>
          <b>Do 31.12.</b> ke každé první měsíční objednávce získáte{" "}
          <b>2 dny ZDARMA navíc*!</b>
          <br />
          Začněte nový rok s námi, jídlo rozvážíme od <b>2.1.2022</b>.
          <br />
          Těšíme se na vás v novém roce!
          <br />
          <br />
          <smmall>
            *nelze kombinovat se slevami a množstevními objednávkami
          </smmall>
        </p>
        <br /> */}
        {/* </AnimatedWrapper> */}
        <div className={styles2.formWrapContainer}>
          <div className={styles2.formInnerContainer}>
            <MainForm2/>
          </div>
        </div>
      </Container>
    </section>
  )
}
